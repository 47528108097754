import { Controller } from '@hotwired/stimulus'

/**
 * Stimulus controller allowing visibility tansitions
 * Built for implementing TailwindUI transitions
 *
 *
 * # Usage
 * 1. Add the controller to a parent element of all elements which will
 *    be transitioned
 * 2. Add `data-transition-visible="false"` (or `true`) to that element
 * 3. Add transition data-attributes to the elements which will be transitioned
 * 4. Add `data-action="click->transition#toggle"` to the elements that trigger
 *    the transition
 * 5. If any elements need to have `display: none` set when not visible, so that
 *    they are no longer interactable, then check the info below
 *    under `# Setting display: none on elements after leave transition`
 *
 *
 * # Transition attributes
 * ```
 * data-transition-enter="ease-in-out duration-300"
 * data-transition-enter-from="opacity-0"
 * data-transition-enter-to="opacity-100"
 * data-transition-leave="ease-in-out duration-300"
 * data-transition-leave-from="opacity-100"
 * data-transition-leave-to="opacity-0"
 * ```
 *
 * # Setting display: none on elements after leave transition
 * [Optional]
 * To hide elements from the DOM, e.g. mobile sidebar overlay when opacity is
 * set to 0 in leaveTo, add the nodes as a `controlDisplay` target and set the
 * `data-transition-hide-after="300"` attribute on the main controller element.
 * This number is the number of milliseconds before a node will be hidden after
 * the leave transition. It defaults to 0.
 *
 * # Other Options
 * data-transition-auto-close="500" // milliseconds before the element is automatically hidden
 * data-transition-target="outside" // If set, clicking this element will set the element to not visible
 */
export default class TransitionController extends Controller {
  static targets = ['animatable', 'controlDisplay', 'outside']

  initialVisible = false

  connect() {
    this.initialVisible = this.isVisible

    if (this.isVisible) {
      this.addClasses('enterTo')
      this.startAutoClose()
    } else {
      this.addClasses('leaveTo')
      this.controlDisplayTargets.forEach((t) => t.classList.add('hidden'))
    }

    document.addEventListener('keyup', this.handleKeyUp)

    this.outsideTargets.forEach((el) => {
      el.addEventListener('click', this.clickOutside, true)
    })

    document.addEventListener('click', this.documentClick)

    document.addEventListener('turbo:before-cache', this.reset)
  }

  disconnect() {
    document.removeEventListener('keyup', this.handleKeyUp)
    this.outsideTargets.forEach((el) => {
      el.removeEventListener('click', this.clickOutside)
    })
    document.removeEventListener('click', this.documentClick)
    document.removeEventListener('turbo:before-cache', this.reset)
  }

  reset = () => {
    if (this.isVisible !== this.initialVisible) {
      this.toggle()
    }
  }

  handleKeyUp = (event) => {
    const isCombinedKey = event.ctrlKey || event.altKey || event.shiftKey
    if (event.key === 'Escape' && !isCombinedKey) {
      this.onEscape()
    }
  }

  onEscape = async () => {
    if (this.isVisible && this.isCancellable) {
      await this.toggle()
    }
  }

  documentClick = (event) => {
    if (
      !this.clickOutsideDetectionEnabled ||
      !this.isVisible ||
      !this.isCancellable
    ) {
      return
    }

    if (this.element.contains(event.target)) {
      return
    }

    this.clickOutside(event)
  }

  autoCloseTimeout = null
  startAutoClose = () => {
    if (this.autoCloseMs == null) {
      return
    }

    this.autoCloseTimeout = setTimeout(() => {
      if (!this.isVisible) {
        return
      }

      this.toggle()
    }, this.autoCloseMs)
  }

  toggle = async (evt) => {
    if (this.autoCloseTimeout != null) {
      clearTimeout(this.autoCloseTimeout)
    }

    this.isVisible = !this.isVisible

    /*
     * The transition between visibility states consists of these 5 steps:
     *   1. Remove all classes from the opposite direction that aren't in our desired start state
     *        For example, if we're transitioning to 'visible' then remove all the 'leave' classes except the ones
     *        we need for 'enterFrom'
     *   2. Add all the classes in our desired start state (enterFrom or leaveFrom)
     *   3. Add the transition classes (enter or leave)
     *   4. Add the classes in the desired end state (enterTo or leaveTo)
     *   5. Remove classes that were in the 'from' state that aren't also in the 'to' state
     */

    if (this.isVisible) {
      this.controlDisplayTargets.forEach((t) => t.classList.remove('hidden'))
      await this.nextFrame()
      this.clearClasses('leaveFrom', 'enterFrom')
      this.clearClasses('leave', 'enterFrom')
      this.clearClasses('leaveTo', 'enterFrom')
      await this.nextFrame()
      this.addClasses('enterFrom')
      this.addClasses('enter')
      this.addClasses('enterTo')
      await this.nextFrame()
      this.clearClasses('enterFrom', 'enterTo')
      await this.nextFrame()
      this.startAutoClose()
    } else {
      this.clearClasses('enterFrom', 'leaveFrom')
      this.clearClasses('enter', 'leaveFrom')
      this.clearClasses('enterTo', 'leaveFrom')
      await this.nextFrame()
      this.addClasses('leaveFrom')
      this.addClasses('leave')
      this.addClasses('leaveTo')
      await this.nextFrame()
      this.clearClasses('leaveFrom', 'leaveTo')

      // We need to wait for the transitions to finish before hiding the controlled elements
      let hideAfter = this.data.get('hideAfter')
      if (hideAfter != null) {
        hideAfter = parseInt(hideAfter, 10) || 0
      }
      await this.delay(hideAfter)
      await this.nextFrame()
      this.controlDisplayTargets.forEach((t) => t.classList.add('hidden'))
    }

    if (this.isVisible) {
      this.element.dispatchEvent(new CustomEvent('transition-visible'))
    } else {
      this.element.dispatchEvent(new CustomEvent('transition-hidden'))
    }
  }

  clickOutside = (event) => {
    if (!this.isVisible) {
      return
    }

    event.preventDefault()
    this.toggle()
  }

  delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  nextFrame() {
    return new Promise((resolve) => {
      requestAnimationFrame(() => {
        requestAnimationFrame(resolve)
      })
    })
  }

  /**
   * @param setName - The name of the set of classes to clear, e.g. 'enterTo'
   * @param exceptSetName - The name of the set of classes not to remove, e.g. 'enterFrom'
   */
  clearClasses(setName, exceptSetName) {
    this.animatables.forEach((animatableEl) => {
      this.clearClassesFromElement(animatableEl, setName, exceptSetName)
    })
  }

  addClasses(setName) {
    this.animatables.forEach((animatableEl) => {
      this.addClassesToElement(animatableEl, setName)
    })
  }

  /**
   * @param el - The element to clear the class on
   * @param setName - The name of the set of classes to clear, e.g. 'enterTo'
   * @param exceptSetName - The name of the set of classes not to remove, e.g. 'enterFrom'
   */
  clearClassesFromElement(el, setName, exceptSetName) {
    const classes = this[setName](el)
    const exceptClasses = this[exceptSetName](el)
    for (let klass of classes) {
      if (!exceptClasses || !exceptClasses.includes(klass)) {
        el.classList.remove(klass)
      }
    }
  }

  /**
   * @param el - The element to add the class on
   * @param setName - The name of the set of classes to add, e.g. 'enterTo'
   */
  addClassesToElement(el, setName) {
    const classes = this[setName](el)
    for (let klass of classes) {
      el.classList.add(klass)
    }
  }

  get animatables() {
    const animatableId = this.data.get('animatable-id')
    if (animatableId != null) {
      return document.getElementById(animatableId)
    } else {
      return this.animatableTargets
    }
  }

  get isVisible() {
    return this.data.get('visible') === 'true'
  }

  set isVisible(newValue) {
    this.data.set('visible', newValue ? 'true' : 'false')
  }

  get isCancellable() {
    return this.data.get('cancellable') === 'true'
  }

  enter(el) {
    return el.dataset.transitionEnter?.split(' ') || []
  }

  enterFrom(el) {
    return el.dataset.transitionEnterFrom?.split(' ') || []
  }

  enterTo(el) {
    return el.dataset.transitionEnterTo?.split(' ') || []
  }

  leave(el) {
    return el.dataset.transitionLeave?.split(' ') || []
  }

  leaveFrom(el) {
    return el.dataset.transitionLeaveFrom?.split(' ') || []
  }

  leaveTo(el) {
    return el.dataset.transitionLeaveTo?.split(' ') || []
  }

  get autoCloseMs() {
    const ms = this.data.get('autoClose')
    if (!ms) {
      return
    }
    return parseInt(ms, 10)
  }

  get clickOutsideDetectionEnabled() {
    const val = this.data.get('clickOutsideDetectionEnabled')
    return val == null || val === 'true'
  }
}
