import { Controller } from '@hotwired/stimulus'
import { put } from '@rails/request.js'

export default class NotificationsController extends Controller {
  async read() {
    await put('/api/notifications', {
      body: {
        read_at: new Date().toISOString(),
      },
      contentType: 'application/json',
      responseKind: 'turbo-stream',
    })
  }

  async reload() {
    document.querySelectorAll('turbo-frame#notifications').forEach((frame) => {
      frame.reload()
    })
  }
}
