import '@hotwired/turbo-rails'
import 'chartkick/chart.js'
import LocalTime from 'local-time'
import '../components'
import './controllers'
import cookieconsent from './lib/cookieconsent'

require('@rails/activestorage').start()
require('./channels')
require('trix')
require('@rails/actiontext')

// Non-Stimulus
document.addEventListener('turbo:load', () => {
  LocalTime.start()
  cookieconsent()
})
