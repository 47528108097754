import { Controller } from '@hotwired/stimulus'
import Clipboard from 'clipboard'

export default class CopybuttonController extends Controller {
  static targets = ['text']

  static values = {
    text: String,
    success: String,
    error: String,
  }

  connect() {
    this.clipboard = new Clipboard(this.element)

    this.clipboard.on('success', (e) => {
      this.flash(this.successValue)
    })

    this.clipboard.on('error', function (e) {
      this.flash(this.errorValue)
    })
  }

  flash = (text) => {
    this.textTarget.innerHTML = text
    setTimeout(() => {
      this.textTarget.innerHTML = this.textValue
    }, 1500)
  }

  disconnect() {
    this.clipboard.destroy()
  }
}
