import { Controller } from '@hotwired/stimulus'
export default class SelectController extends Controller {
  static targets = ['input']
  static values = {
    filterOptions: Object,
    filteredBy: Array,
    initialSelected: String,
  }
  connect() {
    this.filterFields.forEach((filterField) => {
      filterField.addEventListener('change', this.onFilterChange)
    })
    const initialFilterValues = this.filterFields.reduce(
      (values, filterField) => {
        values[filterField.id] = filterField.value
        return values
      },
      {}
    )
    this.updateForFilter(initialFilterValues)
    if (this.initialSelectedValue) {
      this.inputTarget.value = this.initialSelectedValue
    }
  }
  disconnect() {
    this.filterFields.forEach((filterField) => {
      filterField.removeEventListener('change', this.onFilterChange)
    })
  }
  onFilterChange = (e) => {
    const filterValues = this.filterFields.reduce((values, filterField) => {
      values[filterField.id] = filterField.value
      return values
    }, {})
    this.updateForFilter(filterValues)
  }
  updateForFilter = (filterValues) => {
    console.log('filterValues:', filterValues)
    let filteredOptions = this.filterOptionsValue
    console.log('initial filteredOptions:', filteredOptions)

    this.filteredByValue.forEach((filterField) => {
      const filterValue = filterValues[filterField]
      console.log('filterField:', filterField, 'filterValue:', filterValue)
      filteredOptions = filteredOptions?.[filterValue] || []
      console.log('updated filteredOptions:', filteredOptions)
    })
    const existingOptions = this.inputTarget.querySelectorAll('option')
    existingOptions.forEach((option, i) => {
      if (i === 0 && option.value === '') {
        return
      }
      option.remove()
    })
    filteredOptions?.forEach((option) => {
      const optionElement = document.createElement('option')
      optionElement.value = option[1]
      optionElement.innerText = option[0]
      this.inputTarget.appendChild(optionElement)
    })
  }
  get filterFields() {
    const enclosingForm = this.inputTarget.closest('form')
    return this.filteredByValue.map((fieldName) =>
      enclosingForm.querySelector(`#${fieldName}`)
    )
  }
}
