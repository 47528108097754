import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash/debounce'

export default class FormController extends Controller {
  static targets = ['form']

  static values = {
    debounce: 350,
  }

  connect() {
    this.submit = debounce(this.submit, this.debounceValue)
  }

  submit(e) {
    this.formTarget.requestSubmit()
  }
}
