import { Controller } from '@hotwired/stimulus'
import { createPopper } from '@popperjs/core'

export default class TooltipController extends Controller {
  static targets = ['element', 'tooltip']

  connect() {
    this.popperInstance = createPopper(this.elementTarget, this.tooltipTarget, {
      placement: this.placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
        {
          name: 'preventOverflow',
          options: {
            padding: 4,
          },
        },
      ],
    })
  }

  show() {
    // Make the tooltip visible
    this.tooltipTarget.setAttribute('data-show', '')

    // Enable the event listeners
    this.popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [
        ...options.modifiers,
        { name: 'eventListeners', enabled: true },
      ],
    }))

    // Update its position
    this.popperInstance.update()
  }

  hide() {
    // Hide the tooltip
    this.tooltipTarget.removeAttribute('data-show')

    // Disable the event listeners
    this.popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [
        ...options.modifiers,
        { name: 'eventListeners', enabled: false },
      ],
    }))
  }

  // Destroy the Popper instance
  disconnect() {
    if (this.popperInstance) {
      this.popperInstance.destroy()
    }
  }

  get placement() {
    return this.data.get('placement') || 'bottom'
  }
}
