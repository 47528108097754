import { Controller } from '@hotwired/stimulus'

export default class FieldToggleController extends Controller {
  static targets = ['item']

  connect() {
    this.update()
  }

  update() {
    const checkedItem = this.itemTargets.find((item) => item.checked)
    const visible = JSON.parse(checkedItem.dataset.visible)
    const hidden = JSON.parse(checkedItem.dataset.hidden)

    visible.map((id) => this.show(id))
    hidden.map((id) => this.hide(id))
  }

  show(id) {
    this.form.querySelector(`#${id}_wrapper`).classList.remove('hidden')
  }

  hide(id) {
    this.form.querySelector(`#${id}_wrapper`).classList.add('hidden')
  }

  get form() {
    return this.element.closest('form')
  }
}
