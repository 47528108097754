import Tagify from '@yaireo/tagify'
import { Controller } from '@hotwired/stimulus'

export default class TagInputController extends Controller {
  static targets = ['input']

  static values = {
    items: [],
  }

  connect() {
    this.tagify = new Tagify(this.inputTarget, {
      whitelist: this.itemsValue,
      dropdown: {
        enabled: 1, // show suggestions dropdown after 1 typed character
      },
    })
  }

  disconnect() {
    this.tagify.destroy()
  }
}
